body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  min-height: 100%;
  /* font-size: 10px; */
  font-size: 62.5%;
}

#root {
  min-height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: inherit;
}

code {
  font-family: source-code-pro, Menlom, Monaco;
}

/* 아래 부분은 umk에서만 적용될 global css */

@font-face {
  font-family: "GmarketB";
  src: url("./asset/font/GmarketSansTTFBold.ttf");
}
@font-face {
  font-family: "GmarketM";
  src: url("./asset/font/GmarketSansTTFMedium.ttf");
}
@font-face {
  font-family: "GmarketL";
  src: url("./asset/font/GmarketSansTTFLight.ttf");
}
@font-face {
  font-family: "NanumSquareEB";
  src: url("./asset/font/NanumSquare_acEB.ttf");
}
@font-face {
  font-family: "NanumSquareB";
  src: url("./asset/font/NanumSquare_acB.ttf");
}
@font-face {
  font-family: "NanumSquareR";
  src: url("./asset/font/NanumSquare_acR.ttf");
}
@font-face {
  font-family: "NanumSquareL";
  src: url("./asset/font/NanumSquare_acL.ttf");
}

body {
  background: transparent
    linear-gradient(
      164deg,
      #25354f 0%,
      #202e45 14%,
      #1d2a3f 28%,
      #182334 46%,
      #070b10 100%
    )
    0% 0% no-repeat padding-box;
  opacity: 1;

  color: #ffffff;
  font-family: "NanumSquareR";
}
